import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'map-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnDestroy, AfterViewInit {
  @Input()
  time: number;

  @Output()
  done: EventEmitter<any> = new EventEmitter();

  count: number = 20;

  private interval;

  constructor() {
    this.reset();
  }

  ngAfterViewInit(): void {
    this.reset();
    this.interval = this.makeInterval();
  }

  makeInterval() {
    return setInterval(() => {
      this.count--;
      if (this.count <= 0) {
        this.done.emit('');
        this.reset();
      }
    }, 1000);
  }

  reset() {
    if (this.time && this.count != this.time) {
      this.count = this.time;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
