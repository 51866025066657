<map-timer time="20" (done)="reload()"></map-timer>

<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
  </div>
</div>

<div class="warning-features" *ngIf="features.length == 0">
  <div class="card-warning">
    <mat-icon>error</mat-icon>
    <h1>Você não possui nenhum link de rastreio.</h1>
    <p>
      Para adicionar um link, solicite um token a nossa equipe e adicione por
      meio do campo {{ !isMobile ? "ao lado" : "em nosso menu" }}.
    </p>
  </div>
</div>
