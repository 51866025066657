import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { CardFeatureComponent } from './card-feature/card-feature.component';
import { UtilMaterialModule } from 'src/app/utils/material.modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [SearchComponent, CardFeatureComponent],
  exports: [SearchComponent],
  imports: [CommonModule, UtilMaterialModule, FormsModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchModule {}
