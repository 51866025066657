<mat-toolbar class="header" color="primary">
  <img src="/assets/icons/icon.png" alt="" />
  <h1>Barazal Rastreador</h1>
  <span class="spacer"></span>

  <button mat-button color="secondary" class="sm-only" (click)="openSideNav()">
    <mat-icon>
      menu
    </mat-icon>
  </button>
  <button
    mat-button
    color="secondary"
    (click)="changeTheme(!service.store?.theme.dark)"
    class="sm-hidden"
  >
    <mat-icon *ngIf="service.store?.theme.dark"> brightness_2 </mat-icon>
    <mat-icon *ngIf="!service.store?.theme.dark"> brightness_5 </mat-icon>
  </button>
  <a
    mat-button
    color="secondary"
    href="https://bzrastreador.com.br/"
    target="_blank"
    class="sm-hidden"
  >
    <mat-icon> info </mat-icon>
  </a>
</mat-toolbar>
