import { Action } from '@ngrx/store';
import { FeatureModel } from '../models/feature.model';
// Declara instacia das funções que faram a ligação do dispatch da store com o reducers
export enum FeatureActionTypes {
  init = 'INI',
  insert = 'ADD',
  remove = 'REMOVE',
}

export const init = (features: Array<FeatureModel>) => {
  return <Action>{ type: FeatureActionTypes.init, payload: features };
};

export const insert = (feature: FeatureModel) => {
  return <Action>{ type: FeatureActionTypes.insert, payload: feature };
};

export const remove = (feature: FeatureModel) => {
  return <Action>{ type: FeatureActionTypes.remove, payload: feature };
};
