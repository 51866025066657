import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FeatureModel } from 'src/app/models/feature.model';

@Component({
  selector: 'card-feature',
  templateUrl: './card-feature.component.html',
  styleUrls: ['./card-feature.component.scss'],
})
export class CardFeatureComponent implements OnInit {
  @Input()
  public feature: FeatureModel;

  @Output()
  show: EventEmitter<any> = new EventEmitter();
  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  propreties: any;

  constructor() {}

  ngOnInit(): void {
    this.propreties = this.feature.geojson.properties;
  }

  actionDelete() {
    this.delete.emit(this.feature);
  }
  actionShow() {
    this.show.emit(this.feature);
  }
}
