<div class="card">
  <h1 class="title">
    {{ propreties.contrato_name }} <br />
    {{ propreties.title }}
  </h1>
  <h2 class="subtitle">
    {{ propreties.hour * 1000 | date: "dd/MM/yyyy HH:mm:ss" }}
  </h2>
  <div class="infos">
    <p><mat-icon>speed</mat-icon> {{ propreties.speed }} Km/h</p>
    <p>
      <mat-icon>vpn_key</mat-icon>
      {{ propreties.ignition ? " ON " : " OFF " }}
    </p>
  </div>
  <small>
    Expira em:
    {{ propreties.expires_in * 1000 | date: "dd/MM/yyyy HH:mm:ss" }} <br />
  </small>

  <div class="actions">
    <button mat-button color="secondary" (click)="actionDelete()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-raised-button color="secondary" (click)="actionShow()">
      <mat-icon>visibility</mat-icon>
    </button>
  </div>
</div>
