import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { TokenService } from 'src/app/services/token.service';
import { StoreModel } from 'src/app/models/store.model';
import { FeatureModel } from 'src/app/models/feature.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { remove } from 'src/app/actions/feature.action';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit {
  public features: Array<FeatureModel> = [];
  public isMobile;
  constructor(
    private mapService: MapService,
    private service: TokenService,
    private _snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile =
      this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  ngAfterViewInit(): void {
    this.mapService.initMap();
    this.mapService.redux.pipe().subscribe((state) => {
      if ((<StoreModel>(<any>state).store).features) {
        this.features = (<StoreModel>(<any>state).store).features;
      }
    });
  }

  reload() {
    if (this.features) {
      this.features.forEach((feature) => {
        this.service
          .getPosition(feature.token)
          .then()
          .catch((info) => {
            this._snackBar.open(
              feature.geojson.properties.title + ' expirou!',
              'OK',
              {
                duration: 5000,
              }
            );
            this.service.redux.dispatch(remove(feature));
          });
      });
    }
  }
}
