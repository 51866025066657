import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { change, openSideNav } from 'src/app/actions/theme.action';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  dark: boolean = false;
  constructor(public service: StoreService) {}

  ngOnInit(): void {}

  changeTheme(value) {
    this.service.redux.dispatch(change(value));
  }
  openSideNav() {
    this.service.redux.dispatch(openSideNav(true));
  }
}
