// Reducers
import { themeReducer } from './theme.reducer';
// Actions
import { StoreActionTypes } from '../actions/store.action';
import { ThemeActionTypes } from '../actions/theme.action';
// Models
import { StoreModel } from '../models/store.model';
import { ActionModel } from '../models/action.model';
import { FeatureActionTypes } from '../actions/feature.action';
import { featureReducer } from './feature.reducer';

export var store = new StoreModel();

export function storeReducer(state = store, action: ActionModel) {
  state = Object.assign({}, state);

  if (checkActionInReducer(ThemeActionTypes, action.type)) {
    state.theme = themeReducer(state.theme, action);
    return saveState(state);
  } else if (checkActionInReducer(FeatureActionTypes, action.type)) {
    state.features = featureReducer(state.features, action);
    return saveState(state);
  } else {
    switch (action.type) {
      case StoreActionTypes.InitLocal: {
        try {
          state = JSON.parse(action.payload);
          state.theme = themeReducer(state.theme, <ActionModel>{
            type: ThemeActionTypes.change,
            payload: state.theme.dark,
          });
        } catch (error) {
          state = new StoreModel();
        }
        return saveState(state);
      }
    }
  }
}

function checkActionInReducer(objc, type) {
  return Object.values(objc).includes(type);
}

function saveState(state: StoreModel) {
  localStorage.setItem('state', JSON.stringify(state));
  return Object.assign({}, state);
}
