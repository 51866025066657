import { StoreService } from './store.service';
import { Injectable, OnDestroy } from '@angular/core';

import * as mapboxgl from 'mapbox-gl';

import { GeoJson, FeatureCollection } from 'src/app/models/map.model';
import { StyleMapTypes } from '../enums/style-map.enum';
import { FeatureModel } from '../models/feature.model';

@Injectable({
  providedIn: 'root',
})
export class MapService extends StoreService {
  map: any;
  source: any;

  style: string = StyleMapTypes.light;

  lat: number = -22.9472;
  lng: number = -46.3742;
  ownerMarker: any = {};

  // data
  markers: FeatureCollection;

  icons = [];

  public initMap(): void {
    this.getUserPosition();
    this.watch();
    this.build();
  }

  getUserPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.ownerMarker = new mapboxgl.Marker();
        this.ownerMarker.setLngLat([this.lng, this.lat]);
        this.ownerMarker.addTo(this.map);
        this.map.flyTo({
          center: [this.lng, this.lat],
        });
      });
    }
  };

  watch = () => {
    this.watcherStore.push(
      this.redux.pipe().subscribe((data) => {
        if ((<any>data).store) {
          this.checkStyle((<any>data).store.theme);
          this.updateMarkers((<any>data).store.features);
        }
      })
    );
  };

  checkStyle(theme) {
    if (this.style != theme.styleMap) {
      this.style = theme.styleMap;
      if (this.map) this.map.setStyle(this.style);
    }
  }

  updateMarkers(features: Array<FeatureModel>) {
    this.markers = new FeatureCollection(features);
    this.makeIcons(this.markers.features);
    if (this.source) {
      this.source.setData(this.markers);
    }
  }

  build() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 13,
      center: [this.lng, this.lat],
    });

    this.icons.forEach((icon) => this.addIcon(icon));

    /// Add map controls
    // this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', (event) => {
      this.map.addSource('bzrastreador-api-v2', {
        type: 'geojson',
        data: this.markers,
      });

      /// get source
      this.source = this.map.getSource('bzrastreador-api-v2');

      this.map.addLayer({
        id: 'bzrastreador-api-v2',
        source: 'bzrastreador-api-v2',
        type: 'symbol',
        layout: {
          'text-field': '{title}',
          'text-size': 14,
          'text-transform': 'uppercase',
          'icon-image': '{icon}',
          'text-offset': [0, 3],
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': '#cecece',
          'text-halo-width': 2,
        },
      });
    });
  }

  flyTo = (data: GeoJson) => {
    this.map.flyTo({
      center: data.geometry.coordinates,
    });
  };

  makeIcons(features: Array<GeoJson>) {
    features.forEach((feature) => {
      let idx = this.icons.findIndex((icon) => {
        return icon == feature.properties.icon;
      });
      if (idx == -1) {
        this.icons.push(feature.properties.icon);
        if (this.map) {
          this.addIcon(feature.properties.icon);
        }
      }
    });
  }

  addIcon(icon) {
    var img = new Image();
    img.src = icon;
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      if (!this.map.hasImage(icon)) this.map.addImage(icon, img);
    };
  }
}
