import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { TimerComponent } from './timer/timer.component';
import { InstructorComponent } from './instructor/instructor.component';
import { UtilMaterialModule } from 'src/app/utils/material.modules';

@NgModule({
  declarations: [MapComponent, TimerComponent, InstructorComponent],
  exports: [MapComponent],
  imports: [CommonModule, UtilMaterialModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MapModule {}
