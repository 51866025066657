//ANGULAR
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
//STORE MODULES
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
//PAGES
import { TokenPageComponent } from './pages/token-page/token-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
//EXTERNAL MODULES
import { DeviceDetectorModule } from 'ngx-device-detector';
//CUSTOM MODULES
import { AppComponent } from './app.component';
import { HeaderModule } from './components/header/header.module';
import { MapModule } from './components/map/map.module';
import { SearchModule } from './components/search/search.module';
import { UtilMaterialModule } from './utils/material.modules';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent, HomePageComponent, TokenPageComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    DeviceDetectorModule.forRoot(),
    //Components
    HeaderModule,
    MapModule,
    SearchModule,

    //Material Modules
    UtilMaterialModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
