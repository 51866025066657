import { ActionModel } from '../models/action.model';
import { ThemeActionTypes } from '../actions/theme.action';
import { StyleMapTypes } from '../enums/style-map.enum';
import { ThemeModel } from '../models/theme.model';

export function themeReducer(state: ThemeModel, action: ActionModel) {
  state = Object.assign({}, state);
  switch (action.type) {
    case ThemeActionTypes.change: {
      if (action.payload) {
        state.styleMap = StyleMapTypes.dark;
        document.body.classList.add('dark');
      } else {
        state.styleMap = StyleMapTypes.light;
        document.body.classList.remove('dark');
      }

      state.dark = action.payload;
      return state;
    }
    case ThemeActionTypes.openSideNav: {
      state.openSideNav = action.payload;
      return state;
    }
  }
}
