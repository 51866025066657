import { ActionModel } from '../models/action.model';
import { FeatureModel } from '../models/feature.model';
import { FeatureActionTypes } from '../actions/feature.action';

export function featureReducer(
  state: Array<FeatureModel>,
  action: ActionModel
) {
  state = Array.prototype.concat(state);
  switch (action.type) {
    case FeatureActionTypes.init: {
      state = action.payload;
      return state;
    }
    case FeatureActionTypes.insert: {
      let ftr = indexOf(state, action.payload);
      if (ftr > -1) {
        state[ftr] = action.payload;
      } else {
        state.push(action.payload);
      }
      return state;
    }
    case FeatureActionTypes.remove: {
      let ftr = indexOf(state, action.payload);
      if (ftr > -1) {
        state.splice(ftr, 1);
      }
      return state;
    }
  }
}

function indexOf(array, obj) {
  let filtered = array.filter((item) => {
    if (item.token == obj.token) return item;
  });
  return filtered.length ? array.indexOf(filtered[0]) : -1;
}
