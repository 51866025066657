import { Action } from '@ngrx/store';

export enum ThemeActionTypes {
  change = 'CHANGE',
  openSideNav = 'openSideNav',
}

export const change = (dark: boolean = false) => {
  return <Action>{ type: ThemeActionTypes.change, payload: dark };
};

export const openSideNav = (opened: boolean) => {
  return <Action>{ type: ThemeActionTypes.openSideNav, payload: opened };
};
