import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { StoreService } from 'src/app/services/store.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { openSideNav } from 'src/app/actions/theme.action';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements AfterViewInit, OnInit {
  modeSideNav: 'over' | 'push' | 'side' = 'side';
  opened: boolean = false;
  deviceInfo = null;
  isMobile: boolean = false;
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(
    private service: StoreService,
    private deviceService: DeviceDetectorService
  ) {}
  ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    if (!this.isMobile) {
      this.opened = true;
      this.service.redux.dispatch(openSideNav(true));
    } else {
      this.modeSideNav = 'over';
      this.service.redux.dispatch(openSideNav(false));
    }
  }
  ngAfterViewInit(): void {
    this.service.redux.pipe().subscribe((data: any) => {
      let theme = (<any>data).store.theme;
      if (this.opened != theme.openSideNav) {
        this.opened = theme.openSideNav;
      }
    });
  }

  close() {
    if (this.isMobile && this.opened)
      this.service.redux.dispatch(openSideNav(false));
  }
}
