<div class="search-component">
  <button
    mat-button
    color="secondary"
    class="close-btn sm-only"
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <h2>Link's de Rastreio</h2>
  <div class="form-token">
    <mat-form-field>
      <mat-label> Adicionar novo link</mat-label>
      <input matInput required [formControl]="token" />
      <mat-error *ngIf="token.invalid">{{ getErrorMessage() }}</mat-error>
    </mat-form-field>
    <button mat-button color="secondary" (click)="findToken()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <div class="list" *ngIf="features.length">
    <card-feature
      *ngFor="let feature of features"
      [feature]="feature"
      (show)="flyTo(feature)"
      (delete)="delete(feature)"
    >
    </card-feature>
  </div>
</div>
