import { GeoJson } from './map.model';

export class FeatureModel {
  public token: string;
  public geojson: GeoJson;
  constructor(token, data: any) {
    this.token = token;
    this.geojson = new GeoJson(data.coordinates, data.properties);
  }
}
