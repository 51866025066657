import { StoreService } from './store.service';
import { Injectable } from '@angular/core';
import { FeatureModel } from '../models/feature.model';
import { insert } from '../actions/feature.action';
import { RequestApiTypes } from '../enums/api.enum';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends StoreService {
  getPosition(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(RequestApiTypes.HOST + RequestApiTypes.TOKEN_POSITION + token)
        .subscribe(
          (data) => {
            let feature = new FeatureModel(token, data);
            this.redux.dispatch(insert(feature));
            resolve(feature);
          },
          (err) => reject(err)
        );
    });
  }
}
