import { Injectable, OnDestroy } from '@angular/core';
import { StoreModel } from '../models/store.model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { InitLocal } from '../actions/store.action';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StoreService implements OnDestroy {
  public watcherStore: Subscription[] = [];

  public store: StoreModel;

  constructor(public redux: Store<StoreModel>, public http: HttpClient) {
    let store = localStorage.getItem('state');
    this.redux.dispatch(InitLocal(store));
    this.watcherInit();
    mapboxgl.accessToken = environment.mapbox.accessToken;
  }

  // função para observar o state
  watcherInit() {
    this.watcherStore.push(
      this.redux.pipe().subscribe((data: any) => {
        if (data.store) this.store = data.store;
      })
    );
  }

  // se desinscreve antes de distruir o serviço.
  ngOnDestroy() {
    this.watcherStore.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
