import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { UtilMaterialModule } from 'src/app/utils/material.modules';
@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [CommonModule, UtilMaterialModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
