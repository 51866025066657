import { Component, OnInit } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { StoreModel } from 'src/app/models/store.model';
import { FeatureModel } from 'src/app/models/feature.model';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { TokenService } from 'src/app/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { openSideNav } from 'src/app/actions/theme.action';
import { DeviceDetectorService } from 'ngx-device-detector';
import { remove } from 'src/app/actions/feature.action';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public features: Array<FeatureModel>;
  isMobile;
  token = new FormControl('', [Validators.required]);

  constructor(
    private mapService: MapService,
    private tokenService: TokenService,
    private _snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile =
      this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  ngOnInit(): void {
    this.mapService.redux.pipe().subscribe((state) => {
      this.features = (<StoreModel>(<any>state).store).features;
    });
  }

  flyTo(feature: FeatureModel) {
    this.close();
    this.mapService.flyTo(feature.geojson);
  }

  getErrorMessage() {
    if (this.token.hasError('required')) {
      return 'Preencha este campo para adicionar um link.';
    }
  }

  findToken() {
    if (this.token.valid) {
      this.tokenService
        .getPosition(this.token.value)
        .then((feature) => {
          this._snackBar.open('Novo link adicionado!', 'OK', {
            duration: 5000,
          });
          this.flyTo(feature);
        })
        .catch(() => {
          this._snackBar.open('O token é inválido ou já foi expirado!', 'OK', {
            duration: 5000,
          });
        });
      this.token.reset();
    }
  }

  delete(feature: FeatureModel) {
    this.mapService.redux.dispatch(remove(feature));
  }

  close() {
    if (this.isMobile) this.tokenService.redux.dispatch(openSideNav(false));
  }
}
