import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-token-page',
  templateUrl: './token-page.component.html',
  styleUrls: ['./token-page.component.scss'],
})
export class TokenPageComponent implements OnInit {
  token: string;
  constructor(
    private route: ActivatedRoute,
    private service: TokenService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.token = params.get('token');
      this.service
        .getPosition(this.token)
        .then((result) => {
          this.router.navigate(['/']);
        })
        .catch((info) => {
          if (info.status == 400)
            this._snackBar.open(info.error, 'OK', {
              duration: 5000,
            });
          this.router.navigate(['/']);
        });
    });
  }
}
